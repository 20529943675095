// import React, { useState } from "react";
// import { Form, Button, Alert, Container, Row, Col } from "react-bootstrap";
// import emailjs from "@emailjs/browser";
// import { SUCESS_IMG } from "../../Assets";
// import { BACKGROUNDCOLOR } from "../../Themes/theme";

// const FormCom = () => {
//   const [formData, setFormData] = useState({
//     from_name: "",
//     from_email: "",
//     message: "",
//     subject: "Select Services",
//   });

//   const [errors, setErrors] = useState({});
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [isSubmitted, setIsSubmitted] = useState(false);
//   const subjects = [
//     "Select Services",
//     "Product Support",
//     "Cloud Migration",
//     "Cost Optimisation",
//     "Web Development",
//     "Mobile Devlopment",
//     "Other",
//   ];

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const sendEmail = (e) => {
//     e.preventDefault();

//     if (formData.subject === "Select Services") {
//       setErrors({ subject: "Please select a subject" });
//     } else {
//       setErrors({});
//       setIsSubmitting(true);

//       emailjs
//         .sendForm(
//           "service_5wnc29d",
//           "template_7nxm4b7",
//           e.target,
//           "wh96Lfe5CzF3yBm7U"
//         )
//         .then((result) => {
//           console.log(result.text);
//           setIsSubmitting(false);
//           setIsSubmitted(true);
//         })
//         .catch((error) => {
//           console.log(error.text);
//           setIsSubmitting(false);
//           setIsSubmitted(false);
//         });
//     }
//   };

//   return (
//     <section
//       style={{
//         paddingTop: "50px",
//         paddingBottom: "50px",
//         backgroundImage: `url(${BACKGROUNDCOLOR})`,
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//         backgroundRepeat: "no-repeat",
//         textAlign: "center",
//       }}
//     >
//       <Container>
//         <Row>
//           <Col>
//             {isSubmitted ? (
//               <div>
//                 <Alert variant="success">
//                   Successfully submitted! Our team will get back to you.
//                 </Alert>
//                 <img
//                   className="img-fluid mb-4"
//                   src={SUCESS_IMG}
//                   alt="Success"
//                   style={{ width: "50%",  }}
//                 />
//               </div>
//             ) : (
//               <Form onSubmit={sendEmail}>
//                 <Form.Group controlId="from_name">
//                   <Form.Label style={{ fontFamily: "impacted", }}>
//                     Name
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="from_name"
//                     placeholder="Name"
//                   />
//                 </Form.Group>

//                 <Form.Group controlId="from_email">
//                   <Form.Label style={{ fontFamily: "impacted" }}>
//                     Email
//                   </Form.Label>
//                   <Form.Control
//                     type="email"
//                     name="from_email"
//                     placeholder="@gmail.com"
//                   />
//                 </Form.Group>
//                 <Form.Group controlId="subject">
//                   <Form.Label style={{ fontFamily: "impacted" }}>
//                     Services
//                   </Form.Label>
//                   <Form.Control
//                     as="select"
//                     name="subject"
//                     value={formData.subject}
//                     onChange={handleInputChange}
//                     isInvalid={!!errors.subject}
//                   >
//                     {subjects.map((subject) => (
//                       <option key={subject}>{subject}</option>
//                     ))}
//                   </Form.Control>
//                   <Form.Control.Feedback type="invalid">
//                     {errors.subject}
//                   </Form.Control.Feedback>
//                 </Form.Group>
//                 <Form.Group controlId="message">
//                   <Form.Label style={{ fontFamily: "impacted" }}>
//                     Message
//                   </Form.Label>
//                   <Form.Control
//                     as="textarea"
//                     rows={3}
//                     name="message"
//                     placeholder="Hi there, I would like to....."
//                   />
//                 </Form.Group>
//                 <div style={{ marginTop: "20px" }}>
//                   <Button
//                     size="lg"
//                     type="submit"
//                     disabled={isSubmitting}
//                     className="btn btn-primary btn-lg"
//                     style={{
//                       background: BACKGROUNDCOLOR,
//                       borderColor: "#F2D7D5",
//                       borderRadius: "20px",
//                       color: "#17202A",
//                     }}
//                   >
//                     {isSubmitting ? "Submitting..." : "Send"}
//                   </Button>
//                 </div>
//               </Form>
//             )}
//           </Col>
//         </Row>
//       </Container>
//     </section>
//   );
// };

// export default FormCom;
  
import React, { useState } from "react";
import { Form, Button, Alert, Container, Row, Col } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import { SUCESS_IMG } from "../../Assets";
import { BACKGROUNDCOLOR } from "../../Themes/theme";

const FormCom = () => {
  const [formData, setFormData] = useState({
    from_name: "",
    from_email: "",
    message: "",
    subject: "Select Services",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const subjects = [
    "Select Services",
    "Product Support",
    "Cloud Migration",
    "Cost Optimization",
    "Web Development",
    "Mobile Development",
    "Other",
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    // Check if Name and Email are empty
    if (formData.from_name.trim() === "") {
      setErrors({ ...errors, from_name: "Please enter your name" });
    } else if (formData.from_email.trim() === "") {
      setErrors({ ...errors, from_email: "Please enter your email" });
    } else if (formData.subject === "Select Services") {
      setErrors({ ...errors, subject: "Please select a subject" });
    } else {
      setErrors({});
      setIsSubmitting(true);

      emailjs
        .sendForm(
          "service_5wnc29d",
          "template_7nxm4b7",
          e.target,
          "wh96Lfe5CzF3yBm7U"
        )
        .then((result) => {
          console.log(result.text);
          setIsSubmitting(false);
          setIsSubmitted(true);
        })
        .catch((error) => {
          console.log(error.text);
          setIsSubmitting(false);
          setIsSubmitted(false);
        });
    }
  };

  return (
    <section
      style={{
        padding: "50px 0",
        backgroundImage: `url(${BACKGROUNDCOLOR})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        textAlign: "center",
      }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col lg={8} md={10}>
            {isSubmitted ? (
              <div>
                <Alert variant="success">
                  Successfully submitted! Our team will get back to you.
                </Alert>
                <img
                  className="img-fluid mb-4"
                  src={SUCESS_IMG}
                  alt="Success"
                  style={{ maxWidth: "300px" }}
                />
              </div>
            ) : (
              <Form onSubmit={sendEmail}>
                {/* Name Field */}
                <Row className="mb-4">
                  <Col>
                    <Form.Group controlId="from_name">
                      <Form.Label style={{ fontFamily: "impacted" }}>
                        Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="from_name"
                        placeholder="Enter your name"
                        isInvalid={!!errors.from_name}
                        onChange={handleInputChange}
                        className="rounded-pill"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.from_name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Email Field */}
                <Row className="mb-4">
                  <Col>
                    <Form.Group controlId="from_email">
                      <Form.Label style={{ fontFamily: "impacted" }}>
                        Email
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="from_email"
                        placeholder="Enter your email"
                        isInvalid={!!errors.from_email}
                        onChange={handleInputChange}
                        className="rounded-pill"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.from_email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Services Field */}
                <Row className="mb-4">
                  <Col>
                    <Form.Group controlId="subject">
                      <Form.Label style={{ fontFamily: "impacted" }}>
                        Services
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="subject"
                        value={formData.subject}
                        onChange={handleInputChange}
                        isInvalid={!!errors.subject}
                        className="rounded-pill"
                      >
                        <option value="">Select a service</option>
                        {subjects.map((subject) => (
                          <option key={subject} value={subject}>
                            {subject}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.subject}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Message Field */}
                <Row className="mb-4">
                  <Col>
                    <Form.Group controlId="message">
                      <Form.Label style={{ fontFamily: "impacted" }}>
                        Message
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        name="message"
                        placeholder="Hi there, I would like to..."
                        className="rounded"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/* Submit Button */}
                <Row className="text-center">
                  <Col>
                    <Button
                      size="lg"
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-primary"
                      style={{
                        backgroundColor: BACKGROUNDCOLOR,
                        borderColor: "#F2D7D5",
                        borderRadius: "30px",
                        padding: "10px 30px",
                        color: "#17202A",
                      }}
                    >
                      {isSubmitting ? "Submitting..." : "Send"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FormCom;