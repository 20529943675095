// import React from "react";
// import { Container, Row, Col, Button } from "react-bootstrap";
// import "./jobRole.css"; // Create this CSS file to add custom styles
// import { useLocation } from "react-router-dom";
// import { DURATION, WHATWEOFFER } from "../../Config/JDconfig";

// const JobRolePage = () => {
//   const location = useLocation();
//   const {
//     title,
//     JobOverview,
//     KeyResponsibilities = [],
//     SkillsAndExperience = [],
//     EmploymentType,
//   } = location.state || {};

//   return (
//     <Container style={{ marginTop: "120px" }}>
//       <div
//         className="section-title"
//         data-aos="fade-up"
//         style={{
//           textAlign: "center",
//           padding: "10px",
//           borderRadius: "20px",
//         }}
//       >
//         <h1
//           style={{
//             fontFamily: "impact",
//             margin: "0",
//           }}
//         >
//           {title || "Job Role"}
//         </h1>
//       </div>
//       <Row className="my-4">
//         <Col>
//           <h5>Employment Type:</h5>
//           <p>{EmploymentType || "No description available."}</p>
//         </Col>
//       </Row>
//       <Row className="my-4">
//         <Col>
//           <h5>Job Overview:</h5>
//           <p>{JobOverview || "No description available."}</p>
//         </Col>
//       </Row>
//       <Row className="my-4">
//         <Col>
//           <h5>Roles and Responsibilities:</h5>
//           <ul>
//             {Array.isArray(KeyResponsibilities) &&
//             KeyResponsibilities.length > 0 ? (
//               KeyResponsibilities.map((responsibility, index) => (
//                 <li key={index}>{responsibility}</li>
//               ))
//             ) : (
//               <li>No responsibilities available.</li>
//             )}
//           </ul>
//         </Col>
//       </Row>
//       <Row className="my-4">
//         <Col>
//           <h5>Skills And Qualifications:</h5>
//           <ul>
//             {Array.isArray(SkillsAndExperience) &&
//             SkillsAndExperience.length > 0 ? (
//               SkillsAndExperience.map((SkillsAndExperience, index) => (
//                 <li key={index}>{SkillsAndExperience}</li>
//               ))
//             ) : (
//               <li>No responsibilities available.</li>
//             )}
//           </ul>
//         </Col>
//       </Row>

//       <Row className="my-4">
//         <Col>
//           <h5>What we offer:</h5>
//           <p>{WHATWEOFFER || "No description available."}</p>
//         </Col>
//       </Row>

//       <Row className="my-4">
//         <Col>
//           <h5>Duration and Compensation:</h5>
//           <p>{DURATION || "No description available."}</p>
//         </Col>
//       </Row>

//       <Button
//         variant="dark"
//         className="apply-now-button"
//         onClick={() => {
//           window.location.href =
//             // "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=https://prathik.d2m2wtcivnya67.amplifyapp.com/CareerForm";
//           "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=http://localhost:3000/CareerForm";
//         }}
//         style={{ marginBottom: "40px" }}
//       >
//         Apply Now <span className="arrow">→</span>
//       </Button>
//     </Container>
//   );
// };

// export default JobRolePage;

import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./jobRole.css"; // Create this CSS file to add custom styles
import { useLocation, useNavigate } from "react-router-dom";
import { DURATION, WHATWEOFFER } from "../../Config/JDconfig";

const JobRolePage = () => {
  const location = useLocation();
  const {
    title,
    JobOverview,
    KeyResponsibilities = [],
    SkillsAndExperience = [],
    EmploymentType,
  } = location.state || {};

  const handleLoginClick = () => {
    
    localStorage.setItem("jobTitle", title || ""); // Dynamically store the title
    window.location.href =
     "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=https://www.dhruthzucitech.solutions/CareerForm";
    //  "https://digiauth.auth.ap-south-1.amazoncognito.com/login?client_id=2be765jj2blc5uqi49tib6fvcn&response_type=token&redirect_uri=http://localhost:3000/CareerForm";
  };

  return (
    <Container style={{ marginTop: "120px" }}>
      <div
        className="section-title"
        data-aos="fade-up"
        style={{
          textAlign: "center",
          padding: "10px",
          borderRadius: "20px",
        }}
      >
        <h1
          style={{
            fontFamily: "impact",
            margin: "0",
          }}
        >
          {title || "Job Role"}
        </h1>
      </div>

      <Row className="my-4">
        <Col>
          <h5>Employment Type:</h5>
          <p>{EmploymentType || "No description available."}</p>
        </Col>
      </Row>
      <Row className="my-4">
        <Col>
          <h5>Job Overview:</h5>
          <p>{JobOverview || "No description available."}</p>
        </Col>
      </Row>
      <Row className="my-4">
        <Col>
          <h5>Roles and Responsibilities:</h5>
          <ul>
            {Array.isArray(KeyResponsibilities) &&
            KeyResponsibilities.length > 0 ? (
              KeyResponsibilities.map((responsibility, index) => (
                <li key={index}>{responsibility}</li>
              ))
            ) : (
              <li>No responsibilities available.</li>
            )}
          </ul>
        </Col>
      </Row>
      <Row className="my-4">
        <Col>
          <h5>Skills And Qualifications:</h5>
          <ul>
            {Array.isArray(SkillsAndExperience) &&
            SkillsAndExperience.length > 0 ? (
              SkillsAndExperience.map((SkillsAndExperience, index) => (
                <li key={index}>{SkillsAndExperience}</li>
              ))
            ) : (
              <li>No responsibilities available.</li>
            )}
          </ul>
        </Col>
      </Row>

      <Row className="my-4">
        <Col>
          <h5>What we offer:</h5>
          <ul>
            {Array.isArray(WHATWEOFFER) &&
            WHATWEOFFER.length > 0 ? (
              WHATWEOFFER.map((WHATWEOFFER, index) => (
                <li key={index}>{WHATWEOFFER}</li>
              ))
            ) : (
              <li>No responsibilities available.</li>
            )}
          </ul>
          {/* <p>{WHATWEOFFER || "No description available."}</p> */}
        </Col>
      </Row>

      {/* Conditionally render the "Duration and Compensation" section */}
      {title !== "Cloud Engineer" || "Program/Product Manager" && (
        <Row className="my-4">
          <Col>
            <h5>Duration and Compensation:</h5>
            <p>{DURATION || "No description available."}</p>
          </Col>
        </Row>
      )}

      <Button
        variant="dark"
        className="apply-now-button"
        onClick={handleLoginClick}
        style={{ marginBottom: "40px" }}
      >
        Apply Now <span className="arrow">→</span>
      </Button>
    </Container>
  );
};

export default JobRolePage;