export const WHATWEOFFER = [
  "Hands-On Experience: Apply your skills in real-world projects to gain practical experience.",
  "Mentorship: Benefit from guidance and support from experienced professionals.",
  "Impactful Projects: Contribute to projects that make a positive difference.",
  "Career Growth: Explore opportunities for full-time employment based on your performance and the company's needs.",
];

export const DURATION =
  "This is a 3-month internship position, with a potential for extension based on performance and availability. Compensation for this internship will be in accordance with applicable laws and regulations or as per the company's policy.";

export const CareersData = [
  // {
  //   title: "Intern",
  //   rout: "/JobDescription",
  //   position: "Intern",
  //   description:
  //     "We are looking for a motivated and talented Software Development Intern to join our team. This internship will provide hands-on experience in software development, working alongside experienced professionals. You will have the opportunity to contribute to real projects and gain valuable skills in [specific technologies or tools your company uses, e.g., React, Node.js, AWS].",
  // },
  {
    title: "Cloud Engineer",
    rout: "/JobDescription",
    EmploymentType: "Full Time Employment",
    position: "Employment",
    JobOverview:
      "As a Cloud Operations Engineer at DZ Tech, You'll work closely with the Engineering and Operations teams to streamline workflows and automation, reduce costs and non-conformance, and ensure effective monitoring, testing, and deployment. Your responsibilities will include troubleshooting system issues, participating in on-call rotations, conducting root cause analyses of incidents, and improving monitoring solutions to minimize false positives and identify service health regressions. Additionally, you'll communicate findings to the application team and generate weekly data reports summarizing the application's health. You'll also be responsible for configuring and managing cloud infrastructure and applications, including cross-technology administration, scripting, and monitoring automation execution, with a strong focus on incident management and service restoration.",
      KeyResponsibilities: [
        "Collaborate with Engineering and Operations teams to improve automation of workflows, effective monitoring, infrastructure, code testing, scripting capabilities, deployment with lower costs and reduce non-conformance costs.",
        "Troubleshoot and resolve problems across various applications.",
        "Participate in the on-call rotation and conduct root cause analysis of incidents.",
        "Implement enhancements to the monitoring solution to minimize false positives and identify service health regressions.",
        "Communicate findings in verbal and written format to the application team.",
        "Generate weekly data reports summarizing the health of the application.",
        "Have experience in Windows & Linux and Networking & security (Example: IAM, Authorization) topics.",
        "Be aware of DevOps principles, Design Patterns, Enterprise Architecture Patterns, Microservice Architecture, and have the ability to learn/use a wide variety of open-source technologies and tools.",
        "Be an expert and love to work in large projects in an agile way (SAFe-Framework).",
        "Have experience in AWS services: Serverless Services (Lambda, DynamoDB, API Gateway), Container Services (like ECS, ECR), Monitoring Services (like CloudWatch, X-Ray), Orchestration Tools (Kubernetes, Docker), Security Services (IAM, Secrets Manager), Network Services (VPC), EC2, Backup, S3, CDK, CloudFormation, Step functions, SSM documents.",
        "Having experience in Scripting languages: Python, Bash, Boto.",
        "Have a good understanding and experience supporting Linux server, storage, and network technologies.",
        "Be exposed to SOC1/2, ISO27001/9001 frameworks.",
        "Have excellent communication skills and the ability to communicate to all levels of staff.",
        "Be a self-starter with the ability to work independently.",
        "Be an excellent team player.",
        "Solid skills in scripting and automation technologies (Public Cloud CLI, Terraform, CloudFormation, etc).",
        "Experience in writing scripts for automation, managing logical volumes in Linux, and Linux server hardening as per CIS policy."
      ],
      SkillsAndExperience: [
        "Any relevant industry certifications.",
        "3-5 years of experience in a similar role.",
        "Subject Matter Expert in Linux operating systems (RHEL 6, 7 & 8).",
        "Experience in network topology within Linux servers.",
        "Experience with Public Cloud Platforms (AWS & Azure).",
        "Proven track record migrating on-premises environments to public cloud.",
        "AWS Associate level certification is preferred. AWS professional certification is a plus."
      ]
  },

  {
    title: "Project Manager Intern",
    rout: "/JobDescription/ProjectManager",
    EmploymentType: "Full Time Intern",
    position: "Intern",
    JobOverview:
      "At Dhruthzuci , our Project Managers excel by leading and coordinating a project team's overall performance, scope, cost, and deliverables. Our clients rely on timely and efficient status reports, and as Project Manager, you will drive the charge with project direction, metric definition, and performance management. If you are ready to help our clients and project teams succeed, we would love to meet you! ",
    KeyResponsibilities: [
      "Responsible for managing and controlling IT infrastructure related projects, making use of PM disciplines with the project management methods, tools and systems.",
      "Responsible for delivering on commitments to meet customer expectations and maintaining customer relationships.",
      "Responsible for managing project scope, resource, expenses, schedule, subcontractors, and contractual deliverables, which includes planning, tracking, change control, and risk management. Also, ensure that all projects can be delivered on-time, within scope and within budget.",
      "Responsible for communicating and articulating Dhruthzuci’s unique value to support both clients' and Dhruthzuci’s business growth.",
      "Coordinating and managing internal resources and sub vendors for project delivery.",
    ],
    SkillsAndExperience: [
      "Client facing and IT project management experience.",
      "Excellent communication skills and a challenge taker who can work under high pressure.",
      "English: Proficient.",
      "Must be willing to work in shift time.",
      "Familiar with software engineering and/or Cloud operations.",
      "Project management.",
      "PMP.",
      "Project delivery.",
      "IT project management.",
      "Agile.",
      "Risk management.",
      "Program management.",
      "Budgeting.",
      "Change control.",
      "PMO.",
      "Delivery management.",
      "Stakeholder management.",
      "Performance management.",
      "IT infrastructure.",
      "Project planning.",
      "ITIL.",
    ],
  },

  {
    title: "Cloud Engineer Intern",
    position: "Intern",
    EmploymentType: "Full Time intern",
    rout: "/JobDescription",
    JobOverview:
      "As a Cloud Operations Engineer at DZ Tech, You'll work closely with the Engineering and Operations teams to streamline workflows and automation, reduce costs and non-conformance, and ensure effective monitoring, testing, and deployment. Your responsibilities will include troubleshooting system issues, participating in on-call rotations, conducting root cause analyses of incidents, and improving monitoring solutions to minimize false positives and identify service health regressions. Additionally, you'll communicate findings to the application team and generate weekly data reports summarizing the application's health. You'll also be responsible for configuring and managing cloud infrastructure and applications, including cross-technology administration, scripting, and monitoring automation execution, with a strong focus on incident management and service restoration.  ",
    KeyResponsibilities: [
      "Collaborate with Engineering and Operations teams to improve automation of workflows, effective monitoring, infrastructure, code testing, scripting capabilities, deployment with lower costs and reduce non-conformance costs.",
      "Troubleshoot and resolve problems across various applications.",
      "Participate in the on-call rotation and conduct root cause analysis of incidents.",
      "Implement enhancements to the monitoring solution to minimize false positives and identify service health regressions.",
      "Communicate findings in verbal and written format to the application team.",
      "Generate weekly data reports summarizing the health of the application.",
      "Have experience in Windows & Linux and Networking & security (e.g., IAM, Authorization) topics.",
      "Be aware of DevOps principles, Design Patterns, Enterprise Architecture Patterns, Microservice Architecture, and have the ability to learn/use a wide variety of open-source technologies and tools.",
      "Be an expert and love to work in large projects in an agile way (SAFe-Framework).",
      "Have experience in AWS services: Serverless Services (Lambda, DynamoDB, API Gateway), Container Services (ECS, ECR), Monitoring Services (CloudWatch, X-Ray), Orchestration Tools (Kubernetes, Docker), Security Services (IAM, Secrets Manager), Network Services (VPC), EC2, Backup, S3, CDK, CloudFormation, Step functions, SSM documents.",
      "Having experience in Scripting languages: Python, Bash, Boto.",
      "Have a good understanding and experience supporting Linux server, storage, and network technologies.",
      "Be exposed to SOC1/2, ISO27001/9001 frameworks.",
      "Have excellent communication skills and the ability to communicate to all levels of staff.",
      "Be a self-starter with the ability to work independently.",
      "Be an excellent team player.",
      "Solid skills in scripting and automation technologies (Public Cloud CLI, Terraform, CloudFormation, etc).",
      "Experience in writing scripts for automation, managing logical volumes in Linux, and Linux server hardening as per CIS policy.",
    ],

    SkillsAndExperience: [
      "Any relevant industry certifications.",
      "Subject Matter Expert in Linux operating systems (RHEL 6, 7 & 8).",
      "Experience in network topology within Linux servers.",
      "Experience with Public Cloud Platforms (AWS & Azure).",
      "Proven track record migrating on-premises environments to public cloud.",
      "AWS Associate level certification is preferred. AWS professional certification is a plus.",
    ],
  },
  {
    title: "UI UX Designer Intern",
    position: "Intern",
    EmploymentType: "Full Time intern",
    rout: "/JobDescription",
    JobOverview:
      "As a UI/UX Design Intern, you will work closely with our design and product teams to create visually appealing and user-centric designs. This internship provides the opportunity to gain hands-on experience in all phases of the design process, from concept development to prototyping and user testing.  ",
    KeyResponsibilities: [
      "Collaborate with the design team to create wireframes, prototypes, and user interfaces for web and mobile applications.",
      "Assist in designing intuitive user flows and experiences that prioritize usability and user engagement.",
      "Conduct user research, surveys, and usability testing to gather feedback and insights.",
      "Help maintain design consistency and ensure adherence to UI/UX best practices.",
      "Create and optimize visual assets for various digital platforms.",
      "Assist in developing design mockups and high-fidelity prototypes using tools like Sketch, Figma, or Adobe XD.",
      "Work with developers to ensure designs are implemented correctly and deliver the intended user experience.",
      "Participate in brainstorming sessions, design critiques, and project meetings.",
    ],

    SkillsAndExperience: [
      "Currently pursuing a degree or recently graduated in UI/UX Design, Graphic Design, Interaction Design, or a related field.",
      "Strong understanding of design principles, typography, color theory, and user-centered design.",
      "Proficiency in design and prototyping tools such as Adobe Creative Suite, Figma, Sketch.",
      "Basic understanding of HTML/CSS is a plus.",
      "A creative mindset with an eye for detail and a passion for solving user problems.",
      "Strong communication and collaboration skills.",
      "Ability to take constructive feedback and apply it to improve designs.",
      "A portfolio showcasing design projects and an understanding of the UI/UX design process.",
    ],
  },
  {
    title: "IOT Developer | DataScience Intern",
    position: "Intern",
    EmploymentType: "Full Time intern",
    rout: "/JobDescription",
    JobOverview:
      "We are seeking a highly skilled and motivated Data Scientist with AI/ML Specialization to join our team. This role will be instrumental in developing and implementing machine learning models, analyzing complex datasets, and integrating geospatial information to support our environmental and sustainability initiatives. ",
    KeyResponsibilities: [
      "Design, develop, and deploy machine learning algorithms, including supervised and unsupervised learning, recommendation engines, and optimization techniques.",
      "Optimize models for accuracy, performance, and scalability.",
      "Stay updated with the latest advancements in AI/ML and apply relevant techniques to enhance model performance.",
      "Apply deep learning techniques such as RNN, CNN, LSTM, and GANs to solve complex problems.",
      "Utilize natural language processing techniques and algorithms to handle text data and build NLP solutions.",
      "Write clean, efficient code in Python and leverage libraries such as PyTorch, Keras, and TensorFlow.",
      "Analyze and interpret complex datasets related to environmental factors and geospatial information.",
      "Develop and implement data visualization techniques to communicate findings effectively.",
      "Responsible for identifying data requirements, data processing, data cleaning, and working on exploratory and prescriptive data analysis.",
      "Strong understanding of prompt engineering techniques and experience working with GPT 3.5 and GPT 4.",
    ],

    SkillsAndExperience: [
      "Proven experience in analyzing and preparing data for machine learning models, including data cleaning, feature engineering, and model training.",
      "Experience with machine learning frameworks such as TensorFlow, PyTorch, or scikit-learn.",
      "Practical application skills in creating image classification, object detection, semantic segmentation, multi-task, and multi-modal neural networks.",
      "Experienced user of libraries such as OpenCV, scikit-learn, Pandas, SciPy, NumPy, Matplotlib, etc.",
      "Ability to contribute to a variety of data science projects, showing adaptability and versatility in different project environments.",
      "Proficiency in programming languages such as Python, R, or MATLAB.",
      "Skilled in data analysis tools such as SQL, Excel, or Tableau.",
      "Strong problem-solving abilities and the capability to analyze complex datasets to derive actionable insights.",
      "Excellent verbal and written communication skills, with the ability to present technical information to diverse audiences effectively.",
    ],
  },
  // {
  //   title: "Network Engineer/Associate",
  //   position: "Intern",
  //   rout: "/JobDescription",
  //   JobOverview:
  //   "At Dhruthzuci , our Project Managers excel by leading and coordinating a project team's overall performance, scope, cost, and deliverables. Our clients rely on timely and efficient status reports, and as Project Manager, you will drive the charge with project direction, metric definition, and performance management. If you are ready to help our clients and project teams succeed, we would love to meet you!",
  //   Responsibilities: [
  //     "Responsible for managing and controlling IT infrastructure-related projects, using PM disciplines with project management methods, tools, and systems.",
  //     "Responsible for delivering on commitments to meet customer expectations and maintaining customer relationships.",
  //     "Responsible for managing project scope, resources, expenses, schedule, subcontractors, and contractual deliverables, including planning, tracking, change control, and risk management. Ensure that all projects are delivered on-time, within scope, and within budget.",
  //     "Responsible for communicating and articulating Dhruthzuci’s unique value to support both clients' and Dhruthzuci’s business growth.",
  //     "Coordinating and managing internal resources and sub vendors for project delivery."
  //   ],

  //   SkillsAndQualifications: [
  //     "Client-facing and IT project management experience.",
  //     "Excellent communication skills and the ability to work under high pressure.",
  //     "Proficiency in English.",
  //     "Willingness to work in shift time.",
  //     "Familiarity with software engineering and/or Cloud operations.",
  //     "Project management expertise.",
  //     "PMP certification.",
  //     "Experience in project delivery.",
  //     "IT project management skills.",
  //     "Knowledge of Agile methodology.",
  //     "Risk management skills.",
  //     "Program management experience.",
  //     "Budgeting skills.",
  //     "Change control expertise.",
  //     "Experience with PMO (Project Management Office).",
  //     "Delivery management skills.",
  //     "Stakeholder management experience.",
  //     "Performance management skills.",
  //     "IT infrastructure knowledge.",
  //     "Project planning experience.",
  //     "ITIL certification."
  //   ]
  // },
  {
    title: "Marketing Intern",
    position: "Intern ",
    EmploymentType: "Full Time Intern",
    rout: "/JobDescription",
    JobOverview:
      "We are looking for a motivated and talented Software Development Intern to join our team. This internship will provide hands-on experience in software development, working alongside experienced professionals. You will have the opportunity to contribute to real projects and gain valuable skills in [specific technologies or tools your company uses, e.g., React, Node.js, AWS].",

    KeyResponsibilities: [
      "Content Strategy and Creation ",

     " Develop and implement engaging content across various social media platforms to enhance brand visibility. ",
      
     " Craft compelling and shareable content that aligns with the brand's voice and goals. ",
      
      "Community Engagement" ,
      
      "Monitor and respond to comments, messages, and mentions on social media channels." ,
      
      "Foster and grow a vibrant online community through active engagement and relationship building." ,
      
      "Campaign Management" ,
      
      "Plan, execute, and optimize social media campaigns to achieve marketing objectives." ,
      
     " Analyze campaign performance metrics and adjust strategies for continuous improvement. ",
      
      "Social Media Analytics ",
      
     " Track and report on key performance indicators (KPIs) to measure the success of social media efforts. ",
      
     " Provide insights and recommendations based on data analysis. ",
      
      "Collaboration ",
      
     " Work closely with cross-functional teams, including marketing, design, and product teams, to ensure cohesive brand messaging." ,
      
     " Collaborate with influencers and partners to amplify brand reach." ,
      
     " Stay Updated on Industry Trends ",
      
     " Keep abreast of the latest trends, tools, and best practices in social media marketing. ",
      
      "Implement innovative strategies to stay ahead of the competition. "
      
       
    ],

    SkillsAndExperience: [
      "Content Creation: Strong writing and editing skills with the ability to create engaging and shareable content.",
      "Social Media Platforms: Proficient in managing various social media platforms, including but not limited to Facebook, Instagram, Twitter, LinkedIn, and TikTok.",
      "Analytics: Familiarity with social media analytics tools to track and analyze performance metrics.",
      "Creativity: Creative mindset with the ability to think outside the box and contribute fresh ideas.",
      "Communication Skills: Excellent communication and interpersonal skills for effective engagement with the online community.",
      "Adaptability: Ability to adapt to evolving social media trends and algorithm changes.",
      "Team Player: Collaborative approach and the ability to work effectively within a team.",
    ],
  },
  {
    title: "Program|Product Manager",
    position: "Program Coordinator/Manager",
    EmploymentType: "Full Time",
    rout: "/JobDescription",
    JobOverview:
      "We are seeking a seasoned Program Coordinator/Manager with at least 5 years of experience to oversee and manage complex programs within our organization. The ideal candidate will have a strong background in IT project management, excellent client-facing skills, and the ability to work under high pressure while delivering successful project outcomes.",

 KeyResponsibilities: [
    "Program Planning and Execution: Develop and implement comprehensive program plans, including goals, timelines, and resource needs.",
    "Oversee and coordinate program activities to ensure alignment with organizational objectives.",
    "Monitor program progress, address issues, and ensure projects are completed on time and within scope.",
    "Team Leadership and Coordination: Lead cross-functional teams, ensuring effective communication and collaboration.",
    "Manage team performance, resolve conflicts, and maintain a productive work environment.",
    "Resource and Budget Management: Efficiently allocate and manage resources, including budget, personnel, and materials.",
    "Track and control program budgets to ensure financial efficiency and compliance.",
    "Address resource constraints and manage budget adjustments as needed.",
    "Documentation and Reporting: Maintain accurate program documentation, including project plans, reports, and meeting minutes.",
    "Prepare and deliver regular updates and performance reports to stakeholders and senior management.",
    "Ensure timely reporting of program outcomes and metrics.",
    "Quality Assurance and Improvement: Ensure program deliverables meet quality standards and organizational goals.",
    "Implement best practices and continuous improvement processes for program management.",
    "Conduct evaluations to identify areas for enhancement and drive necessary changes.",
    "Risk and Change Management: Identify and manage risks to program success, developing mitigation strategies as needed.",
    "Oversee change control processes to manage scope changes and project impacts.",
    "Stakeholder Engagement: Act as the primary point of contact for program stakeholders, ensuring effective communication and relationship management.",
    "Gather and incorporate stakeholder feedback to improve program outcomes.",
    "Client and IT Project Management: Manage client interactions and expectations effectively, ensuring high levels of satisfaction.",
    "Oversee IT project management activities, including planning, delivery, and performance management.",
    "Ensure alignment with IT infrastructure and cloud operations."
  ],
  SkillsAndExperience: [
    "Education: Bachelor’s degree in Business Administration, Management, Information Technology, or a related field. A Master’s degree is preferred.",
    "Experience: Minimum of 5 years of experience in program coordination or management, with a strong focus on IT project management.",
      "Client-Facing Skills: Proven ability to manage client relationships and expectations.",
      "IT Project Management: Experience in managing IT projects, including software engineering and cloud operations.",
      "Communication Skills: Excellent verbal and written communication skills. Proficient in English.",
      "Project Management: Expertise in project planning, delivery, and management, with hands-on experience in Agile methodologies.",
      "Certifications: Project Management Professional (PMP), ITIL, or similar certifications.",
      "Risk Management: Strong skills in risk management and change control.",
      "Budgeting: Experience in budget management and financial oversight.",
      "Program Management: Demonstrated experience in program management and delivery.",
      "Performance Management: Ability to manage and assess team and project performance.",
      "Shift Work: Willingness to work in shift times as required.",
      "Software and IT Infrastructure: Familiarity with software engineering, IT infrastructure, and cloud operations.",
  ]
  },

  // {
  //   title: "program co-ordinator or manager",
  //   position: "Intern",
  //   rout: "/JobDescription",
  //   description:
  //     "As an Entry-Level Application Engineer, you will be involved in the design, development, and maintenance of software applications. You will work closely with cross-functional teams to understand user requirements, write efficient code, and support the deployment of applications in a production environment.",
  // },
];
