import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import "./CareerForm.css"; // Import the CSS file
import { useLocation, useNavigate } from "react-router-dom";
import { AWS_API_KEY } from "../../Config/config";

const CareerForm = () => {
  const location = useLocation();
  const { title } = location.state || {};
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    role: "",
    file: null,
    gender: "",
    countryname: "",
    statename: "",
    city: "",
    joiningInterest: "",
    status:"submitted",
    assignedTo:null,
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    // Retrieve the title from Local Storage and set it in the form data
    const storedTitle = localStorage.getItem("jobTitle");
    console.log(storedTitle);
    setFormData(prevFormData => ({
      ...prevFormData,
      role: storedTitle || ""
    }));
    // Clear the title from Local Storage
    // localStorage.removeItem("jobTitle");
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
    setErrors({
      ...errors,
      [name]: "", // Clear error on input change
    });
  };

  const handleCountryChange = (e) => {
    setFormData({
      ...formData,
      countryname: e.name,
      countryid: e.id,
      stateid: "",
      city: "",
    });
    setErrors({
      ...errors,
      countryid: "",
    });
  };

  const handleStateChange = (e) => {
    setFormData({
      ...formData,

      statename: e.name,
      stateid: e.id,
      city: "",
    });
    setErrors({
      ...errors,
      stateid: "",
    });
  };

  const handleCityChange = (e) => {
    setFormData({
      ...formData,
      city: e.name,
    });
    setErrors({
      ...errors,
      city: "",
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required";
    if (!formData.phone.trim()) newErrors.phone = "Phone number is required";
    if (!formData.email.trim()) newErrors.email = "Email is required";
    if (!formData.role.trim()) newErrors.role = "Role is required";
    if (!formData.gender.trim()) newErrors.gender = "Gender is required";
    if (!formData.countryid) newErrors.countryid = "Country is required";
    if (!formData.stateid) newErrors.stateid = "State is required";
    if (!formData.city.trim()) newErrors.city = "City is required";
    if (!formData.file) newErrors.file = "File is required";
    if (!formData.joiningInterest.trim())
      newErrors.joiningInterest = "Joining interest is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      alert("Please fill out all required fields.");
      return;
    }

    const {
      file,
      name,
      phone,
      email,
      role,
      gender,
      countryname,
      statename,
      city,
      joiningInterest,
    } = formData;

    const now = new Date();
    const dateApplied = now.toISOString();

    try {
      const encodedRole = encodeURIComponent(role);
      const encodedFileName = encodeURIComponent(file.name);

      const uploadResponse = await fetch(
        `https://ebg1aqe5vd.execute-api.ap-south-1.amazonaws.com/dev/jobhiring%2f${encodedRole}/${encodedFileName}`,
        {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
            Accept: "application/json",
            "x-api-key": AWS_API_KEY,

          },
        }
      );

      if (!uploadResponse.ok) {
        const errorText = await uploadResponse.text();
        console.error("Error uploading file:", errorText);
        alert("Error uploading file: " + errorText);
        return;
      }

      const resumeurl = `https://d746rmdj54fbf.cloudfront.net/${encodedRole}/${encodedFileName}`;

      const formDataToSend = {
        name: String(name),
        email: String(email),
        phoneNumber: Number(phone),
        resumeurl: String(resumeurl),
        dateApplied: dateApplied,
        role: String(role),
        gender: String(gender),
        country: String(countryname),
        state: String(statename),
        city: String(city),
        joiningInterest: String(joiningInterest),
        status: "submitted",
        assignedTo: "null",
      };

      const postResponse = await fetch(
        "https://49aynb7zh0.execute-api.ap-south-1.amazonaws.com/dev/form",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": AWS_API_KEY,

            
          },
          body: JSON.stringify(formDataToSend),
        }
      );

      if (!postResponse.ok) {
        const errorText = await postResponse.text();
        console.error("Error submitting form:", errorText);
        alert("Error submitting form: " + errorText);
        return;
      }

      alert("Form submitted successfully!");
      navigate("/thankyou"); // Redirect to Thank You page
    } catch (error) {
      console.error("Error:", error);
      alert("There was an error processing your request.");
    }
  };

  return (
    <div className="upload-container">
      <Container style={{ marginTop: "100px" }}>
        <h2>Job Application form</h2>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="input-group">
            <label htmlFor="name">
              Name <span className="required">*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Enter your name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {errors.name && <p className="error-text">{errors.name}</p>}
          </div>
          <div className="input-group">
            <label htmlFor="phone">
              Phone <span className="required">*</span>
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Enter your phone number"
              value={formData.phone}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value)) {
                  // Allow only numbers
                  setFormData({ ...formData, phone: value });
                  setErrors({ ...errors, phone: "" });
                }
              }}
              required
            />
            {errors.phone && <p className="error-text">{errors.phone}</p>}
          </div>

          <div className="input-group">
            <label htmlFor="email">
              Email <span className="required">*</span>
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <p className="error-text">{errors.email}</p>}
          </div>

          <div className="form-group">
          <label htmlFor="role">Role:</label>
          <input
            type="text"
            id="role"
            name="role"
            value={formData.role}
            readOnly
            className="form-control"
          />
        </div>
          <div className="input-group">
            <label htmlFor="country">
              Country <span className="required">*</span>
            </label>
            <CountrySelect
              onChange={handleCountryChange}
              placeHolder="Select Country"
              required
            />
            {errors.countryid && (
              <p className="error-text">{errors.countryid}</p>
            )}
          </div>

          <div className="input-group">
            <label htmlFor="state">
              State <span className="required">*</span>
            </label>
            <StateSelect
              countryid={formData.countryid}
              onChange={handleStateChange}
              placeHolder="Select State"
              required
            />
            {errors.stateid && <p className="error-text">{errors.stateid}</p>}
          </div>

          <div className="input-group">
            <label htmlFor="city">
              City <span className="required">*</span>
            </label>
            <CitySelect
              countryid={formData.countryid}
              stateid={formData.stateid}
              onChange={handleCityChange}
              placeHolder="Select City"
              required
            />
            {errors.city && <p className="error-text">{errors.city}</p>}
          </div>

          <div className="input-group">
            <label htmlFor="gender">
              Gender <span className="required">*</span>
            </label>
            <select
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              required
            >
              <option value="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
              <option value="preferNotToSay">Prefer not to say</option>
            </select>
            {errors.gender && <p className="error-text">{errors.gender}</p>}
          </div>

          <div className="input-group">
            <label htmlFor="file">
              Upload File <span className="required">*</span>
            </label>
            <input
              type="file"
              id="file"
              name="file"
              onChange={handleChange}
              required
            />
            {errors.file && <p className="error-text">{errors.file}</p>}
          </div>

          <div className="input-group">
            <label htmlFor="joiningInterest">
              Joining Interest <span className="required">*</span>
            </label>
            <input
              type="text"
              id="joiningInterest"
              name="joiningInterest"
              placeholder="Why are you interested in joining?"
              value={formData.joiningInterest}
              onChange={handleChange}
              required
            />
            {errors.joiningInterest && (
              <p className="error-text">{errors.joiningInterest}</p>
            )}
          </div>

          <button type="submit">Submit</button>
        </form>
      </Container>
    </div>
  );
};

export default CareerForm;
