export const CLOUDLANDING_HERO = require("../../Assets/images/digi_hero.png");
export const DBLUE_RECTANGLE = require("../../Assets/images/Rectangle1.png");
export const LBLUE_RECTANGLE = require("../../Assets/images/Rectangle2.png");


export const DIGI1 = require("../../Assets/images/digi1.png");
export const DIGI2 = require("../../Assets/images/digi2.png");
export const DIGI3 = require("../../Assets/images/digi3.png");
export const DIGI4 = require("../../Assets/images/digi4.png");
export const DIGI5 = require("../../Assets/images/digi5.png");
export const DIGI6 = require("../../Assets/images/digi6.png");
export const DIGI7 = require("../../Assets/images/digi7.png");

export const CloudlandingData = {
  heroSection: {
    title: "Unlocking the Future of Agriculture with Digital Grain Identifier",
    backgroundImage: CLOUDLANDING_HERO,
  },
  introductionSection: {
    title:
      "Introduction",
    description:
      "Welcome to the forefront of agriculture, where innovation and technology converge to redefine the way, we identify and manage rice varieties. Our Digital Grain Identifier, fuelled by cutting-edge AI and ML technologies, is transforming the landscape of rice image classification. Say farewell to time-consuming manual seed identification and embrace the precision and efficiency of automated rice variety recognition. Whether you're a farmer, researcher, or seed producer, our system is here to enhance your agricultural journey.",
  },


  targetCustomersSection: {
    title: "What makes Digital Grain Identifier important?",
    description:
      "Our solution is a perfect fit for a wide array of organizations, including:",
    targetCustomers: [
      {
        image: DIGI1,
        text: [
          {
            title: "Instant Identification:",
            description:
              "Explore the Benefits of AWS Control Tower Discover how AWS Control Tower can revolutionise your AWS landscape. Bid farewell to the complexities of account setup and configuration, and say hello to a centralised, well-architected, and ultra-secure multi-account AWS environment. Whether you're a dynamic startup or a well-established enterprise, AWS Control Tower is designed with you in mind.",
          },
        ],
      },
      {
              text: [
          {
            title: "Enterprises:",
            description: "Robust security measures for large organizations.",
          },
        ],
        image: DIGI2,

      },
      {
        image: DIGI3,
        text: [
          {
            title: "User-Centric Applications:",
            description:
              "Ideal for apps where user experience and security are paramount.",
          },
        ],
      },
      {
        image: DIGI4,
        text: [
          {
            title: "Scalable Applications:",
            description:
              "Designed to accommodate applications with growing user bases.",
          },
        ],
      },
      {
        image: DIGI5,
        text: [
          {
            title: "Scalable Applications:",
            description:
              "Designed to accommodate applications with growing user bases.",
          },
        ],
      },
    ],
  },
  customerEngagementSection: {
    title: "How It Works:",
    description:
      "Explore our advanced Rice Variety Identification system, allowing users to effortlessly upload rice seed images for instant classification. Utilizing state-of-the-art deep learning techniques, our AI model ensures precise identification, classification, and duplicate detection. The user-friendly web interface provides real-time results, supporting vital aspects like breeding programs and crop diversity management. Benefit from seamless AWS integration, guaranteeing a robust and high-performance solution for your rice-related needs.",


    backgroundImage: DBLUE_RECTANGLE,
  },
  deliveryMechanismsSection: {
    title: "The Team behind Digital Grain Identifier ",
    description: "Smooth Transition and Ongoing Support. Rest assured, we are with you every step of the way. Our transition plan, training, and post-implementation support guarantee a seamless shift to your enhanced AWS environment.",
    // image: CLOUDLANDING6,

  },
  AWSValueProposition: {
    title: "AWS Value Proposition",
    description: "Experience the unparalleled advantages of integrating with Amazon Web Services (AWS) for your project. Achieve cost-effectiveness through flexible cost management, resource optimization, and enhanced efficiency. Ensure seamless scalability to meet evolving demands, supporting future growth and maintaining optimal system performance. Benefit from AWS's reliable and redundant infrastructure, minimizing downtime and ensuring uninterrupted system access. Prioritize data security with AWS's secure environment, adhering to industry standards. Simplify deployment and maintenance processes, enhancing overall system manageability. Elevate your project with AWS and unlock a new level of performance and efficiency",

  },

  WhoCanBenefit: {
    title: "Who Can Benefit",
    description: "Discover our advanced web-based system revolutionizing rice variety identification through image classification. Ideal for agricultural researchers, seed producers, farmers, extension services, technology providers, biodiversity conservation organizations, and educational institutions. Our solution offers precise and efficient solutions for rice cultivation, breeding, and crop diversity management. Experience tools tailored for expedited research, seed quality assurance, empowered crop management, educational support, and technological innovation. Uncover the significance of rice variety identification and the application of machine learning in agriculture with our practical teaching tool. Elevate your practices and contributions to the agricultural industry with our state-of-the-art system.",

  },

  EngageWithTechnology: {
    title: " Engage with Cutting-edge Technology",
    description: " Explore our Customer Engagement and Delivery Mechanisms, focusing on an innovative web-based system for automated rice variety identification. Leveraging a robust machine learning model, a comprehensive rice seed image dataset, and AWS services ensures unparalleled accuracy and efficiency. With a foundation built on diverse data and optimized deep learning, our user-friendly interface guarantees a seamless experience. Error handling mechanisms enhance user satisfaction. The integration with AWS for backend processing ensures optimal system performance. Elevate your engagement with cutting-edge technology for precise rice variety classification.",

  },

  MilestonesAndFeedback: {
    title: "Milestones and Feedback",
    description: "Our project follows a structured process. Pre-Engagement Assessment ensures thorough understanding, and Milestone-Defined Checkpoints keep the project on track. Post-Engagement Assessment evaluates outcomes, performance, and lessons learned.",

  },

  CostOptimization: {
    title: "Cost-Optimization Strategies",
    line1: "We employ professional cost-optimization strategies, including leveraging cost-effective AWS pricing options and efficient resource management. ",
    line2: "Unlock the Potential of Rice Variety Identification.",
    line3: "Join us in the future of agriculture with Digital Grain Identifier. Embrace efficiency, precision, and automation in your rice farming, research, and seed production endeavours. With AI/ML-driven rice image classification, you have the power to make informed decisions, enhance productivity, and elevate the quality and quantity of your rice production.",
  },

  getStartedSection: {
    title: "Contact Us",
    description:
      " Ready to transform your rice farming? Have questions or inquiries?  ",
    contactUsLink: "/contactus",
  },
};