import React, { useRef, useState } from "react";
import { FaMapMarkerAlt, FaEnvelope, FaPhone } from "react-icons/fa";
import emailjs from "@emailjs/browser";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { BACKGROUNDCOLOR } from "../../Themes/theme";
import FormCom from "../Form/Form";

const Contact = () => {
  const form = useRef();
  const [showMap, setShowMap] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_0m1g1yd",
        "template_7nxm4b7",
        form.current,
        "wh96Lfe5CzF3yBm7U"
      )
      .then(
        (result) => {
          console.log(result);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleLocationHover = () => {
    setShowMap(true);
  };

  const handleLocationLeave = () => {
    setShowMap(false);
  };

  return (
    <section
      className="contact-section"
      style={{
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <h1
        style={{
          textAlign: "center",
          fontFamily: "impact",
          background: `linear-gradient(to right, #0C1A4A, #000000)`,
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          marginTop: "130px",
          color: "#000000",
          // marginBottom:"7%"
        }}
      >
        Contact Us
      </h1>
      <Container>
        <div
          style={{
            backgroundColor: BACKGROUNDCOLOR,
            borderRadius: "20px",
            padding: "20px",
            boxShadow: "0px 0px 20px rgba(0, 0, 255, 0.2)",
            marginBottom: "40px",
          }}
        >
          <Row>
            <Col lg={6}>
              <FormCom />
            </Col>

            <Col lg={6} style={{ paddingLeft: "20px" }}>
              <div
                style={{
                  backgroundColor: "#0C1A4A",
                  borderRadius: "20px",
                  padding: "20px",
                  boxShadow: "0px 0px 20px rgba(0, 0, 255, 0.2)",
                  color: "#fff",
                }}
              >
                <h2 style={{ fontFamily: "impact", color: "#fff" }}>
                  Get in Touch
                </h2>
                <div className="address details">
                  <h5 style={{ fontFamily: "impact" }}>
                    <h2 style={{ color: "#FFD700", marginTop: "30px" }}>
                      Location &nbsp;
                      <FaMapMarkerAlt
                        style={{
                          // fontSize: "xx-large",
                          color: "#fff",
                          marginRight: "10px",
                          // marginTop:"20px"
                        }}
                      />
                    </h2>
                  </h5>
                  <div className="text" style={{ fontFamily: "impacted" }}>
                    <FaMapMarkerAlt className="icon" color="white" />Head Office:
                    <br />2nd floor, 208 ramya nivasa, 7th cross Shakthi nagar,
                    <br /> Near Cloud Nine Hospital,
                  </div>
                  <div className="text" style={{ fontFamily: "impacted" }}>
                    Horamavu, Bangalore - 560043
                  </div>
                  <br />
                  <div className="text" style={{ fontFamily: "impacted" }}>
                    <FaMapMarkerAlt className="icon" color="white" />
                    16192 Coastal Highway
                    <br />
                    Lewes, DE 19958
                    <br />
                    Sussex County
                    <br />
                    United States
                  </div>
                </div>
                <div className="phone details">
                  <h5 style={{ fontFamily: "impact" }}>
                    <h2 style={{ color: "#FFD700", marginTop: "30px" }}>
                      Phone &nbsp;{" "}
                      <FaPhone
                        style={{
                          // fontSize: "xx-large",
                          color: "#fff",
                          marginRight: "10px",
                          // marginTop:"30px"
                        }}
                      />
                    </h2>
                  </h5>
                  <div className="text" style={{ fontFamily: "impacted" }}>
                    +91 80 35001542
                  </div>
                  <div className="text" style={{ fontFamily: "impacted" }}>
                    +91 80 35001543
                  </div>
                </div>
                <div className="email details">
                  <h5 style={{ fontFamily: "impact", marginTop: "20px" }}>
                    <h2 style={{ color: "#FFD700" }}>
                      Email &nbsp;{" "}
                      <FaEnvelope
                        style={{
                          // fontSize: "xx-large",
                          color: "#fff",
                          marginRight: "10px",
                          // marginTop:"20px"
                        }}
                      />
                    </h2>
                  </h5>
                  <div className="text" style={{ fontFamily: "impacted" }}>
                    <a
                      href="mailto:careerinfo@eshatechgroup.com"
                      style={{
                        fontSize: "large",
                        fontFamily: "impacted",
                        color: "#FFF",
                      }}
                    >
                      careerinfo@eshatechgroup.com
                    </a>
                  </div>
                  <div className="text" style={{ fontFamily: "impacted" }}>
                    <a
                      href="mailto:sales@eshatechgroup.com"
                      style={{
                        fontSize: "large",
                        fontFamily: "impacted",
                        color: "#FFF",
                      }}
                    >
                      sales@eshatechgroup.com
                    </a>
                  </div>
                </div>
                <p style={{ fontFamily: "impacted", color: "#FFF" }}>
                  <br />
                  We'd love to hear from you! Feel free to contact us with any
                  questions or inquiries.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.2274188741208!2d77.64922911577553!3d13.02118479082336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae112bfca27b21%3A0x2c3b82b3a90dd91c!2sDhruthZuci%20Tech%20Solutions%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1588062678971!5m2!1sen!2sin"
              width="500"
              height="500"
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
              id="map"
              style={{ borderRadius: "20px", marginTop: "40px" }}
            ></iframe>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default Contact;